const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "启用",
                prop: "SFQY_DESC",
                width: "60"
            }, {
                label: "设备名称",
                prop: "SBMC",
                width: "300"
            }, {
                label: "遗产要素",
                prop: "YCYSMC"
            }, {
                label: "设备编码",
                prop: "SBBH"
            }, {
                label: "安装时间",
                prop: "AZSJ",
                width: "160"
            }, {
                label: "安装位置",
                prop: "AZDWZMS"
            }, {
                label: "采集周期",
                prop: "CJZQ"
            }, {
                label: "周期单位",
                prop: "CJZQDW_DESC"
            }, {
                label: "设备状态",
                prop: "SBZT_DESC",
                transList: {
                    '故障': '故障',
                    '正常': '正常',
                },
                colorList: {
                    '故障': "#d2413e",
                    '正常': "#4ba1a5",
                },
            }, {
                label: "上次抓取时间",
                prop: "SCZQSJ",
                width: "160"
            }],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        selectData: [
            // {
            //     label: '周期单位',
            //     clearable: true,
            //     placeholder: "请选择周期单位",
            //     list: [],
            //     optionLabel: 'LABEL',
            //     optionValue: 'CODE',
            //     value: 'GRABCYCLEUNIT',
            //     operateType: 'search',
            //     isShow: true
            // },
            {
                label: '设备状态:',
                clearable: true,
                placeholder: "请选择设备状态",
                list: [],
                optionLabel: 'Name',
                optionValue: 'Code',
                value: 'sbzt',
                operateType: 'search',
                isShow: true,
                width: '184px'
            },],
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建',
            round: true,
            backColor: '#28ccd9',
        }, {
            isShow: true,
            btnType: 'button',
            operateType: 'buttonDel',
            name: '批量删除',
            round: true,
            backColor: '#fa6f1b',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }, {
            isShow: true,
            btnType: 'button',
            operateType: 'buttonMapShow',
            name: '地图展示',
            round: true,
            backColor: '#28ccd9',
        }]
    }
};
export { selfData };