<template>
    <div class="equip-manage-base">
        <div class="equip-manage-left scrollbar">
            <div class="title">
                <span class="txt">设备类型</span>
                <div class="iconBorder">
                    <i
                        class="el-icon-plus iconbase"
                        style="color: #515151"
                        title="添加设备类型"
                        @click="add"
                    ></i>
                    <i
                        class="el-icon-edit iconbase"
                        style="color: #515151"
                        title="编辑"
                        @click="edit"
                    ></i>
                    <i
                        class="el-icon-delete iconbase"
                        style="color: #1862ad"
                        title="删除"
                        @click="del"
                    ></i>
                </div>
            </div>
            <div
                class="container"
                :style="{ background: typeValue === item.ID ? '#dfe3eb' : '' }"
                v-for="(item, index) in equipData"
                :key="`${index}`"
                :index="`${index}`"
                @click="clickHandler(item)"
            >
                <b style="width: 100px"></b>
                <span>{{ item.MC }}</span>
                <i :class="typeValue === item.ID ? iconclass : null"></i>
            </div>
        </div>
        <div class="equip-manage-right">
            <el-tabs
                class="equip-tabs"
                v-model="activeName"
                type="card"
                @tab-click="handleClick"
            >
                <el-tab-pane label="类型详情" name="first">
                    <div class="equip-manage-type-form">
                        <el-form
                            ref="formData"
                            class="demo-form-inline form_row"
                            size="small"
                            :rules="rules"
                            :model="formData"
                            label-width="100px"
                        >
                            <el-form-item
                                class="must-fill"
                                prop="MC"
                                label="名称："
                            >
                                <el-input
                                    :readonly="!addVis"
                                    v-model="formData.MC"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="GG" label="规格型号：">
                                <el-input
                                    :readonly="!addVis"
                                    v-model="formData.GG"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="SBCS" label="设备厂商：">
                                <el-input
                                    :readonly="!addVis"
                                    v-model="formData.SBCS"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="YLJJ" label="原理简介：">
                                <el-input
                                    resize="none"
                                    type="textarea"
                                    :readonly="!addVis"
                                    v-model="formData.YLJJ"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="JKSM" label="接口说明：">
                                <el-input
                                    resize="none"
                                    type="textarea"
                                    :readonly="!addVis"
                                    v-model="formData.JKSM"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="BZSM" label="备注说明：">
                                <el-input
                                    resize="none"
                                    type="textarea"
                                    :readonly="!addVis"
                                    v-model="formData.BZSM"
                                    :rows="6"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="监测类型：">
                                <el-cascader
                                    v-model="jcxids"
                                    :show-all-levels="false"
                                    :options="sjjcxsjgData"
                                    :props="optionProps"
                                    filterable
                                    clearable
                                    @change="handleChange"
                                    :disabled="!addVis"
                                >
                                </el-cascader>
                                <!-- <el-select
                                    class="equip-type"
                                    v-model="jcxids"
                                    filterable
                                    placeholder="请选择"
                                    :disabled="!addVis"
                                >
                                    <el-option-group
                                        v-for="group in sjjcxsjgData"
                                        :key="group.ItemID"
                                        :label="group.MC"
                                    >
                                        <el-option
                                            v-for="(item,index) in group.Children"
                                            :key="index"
                                            :label="item.MC"
                                            :value="item.ItemID"
                                        ></el-option>
                                    </el-option-group>
                                </el-select> -->
                            </el-form-item>
                        </el-form>
                        <div class="buttondiv">
                            <button
                                class="saveBtn"
                                v-if="addVis"
                                @click="validate('formData')"
                            >
                                保存
                            </button>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="设备清单" name="second" v-if="isSbqdShow">
                    <div class="equip-list-content" v-if="!isSbqdMapShow">
                        <sn-table-group
                            :tableGroupAttributes="tableGroupAttributes"
                            @handleChange="handleChange"
                        ></sn-table-group>
                    </div>
                    <div class="equip-map-content" v-if="isSbqdMapShow">
                        <button class="back-btn" @click="BackToSbqdTable">
                            返回
                        </button>
                        <EquipmanageMap
                            class="lonlat-map"
                            :mapPoint="mapPoint"
                            :equipData="equipData"
                        ></EquipmanageMap>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import { commonData } from "../sn-table-group-common";
import { selfData } from "./model/sn-table-group";
import commonMethodMixin from "../commonMethodMixin";
import { mapActions, mapGetters } from "vuex";
import * as TYPES from "@store/modules/mutation-type.js";
import EquipmanageMap from "./equipmanage_map";
export default {
    name: "equipconfig_grid",
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            infoPage: "equipmanage_form",
            measureRobotInfoPage: "measure_robot_form", //测量机器人
            // downUrl: DOWN_URL + '/api/FileDownLoad/OnPostExport?funId=0002',
            // 筛选项
            keyword: "", // 关键字

            iconclass: "equipicon el-icon-circle-check",
            typeValue: "",
            equipData: [],
            formData: {},
            addVis: true,
            sjjcxsjgData: [],
            optionProps: {
                value: "ItemID",
                label: "MC",
                children: "Children",
                expandTrigger: "hover",
            },
            rules: {},
            jcxids: [],

            activeName: "first",
            isSbqdShow: true,
            sbLx: null,
            sbMc: "",
            isSbqdMapShow: false,
            mapPoint: [],
            itemID: "116",
            delSomeCount: 0,
            sbzt: "",
        };
    },
    components: {
        EquipmanageMap,
    },
    computed: {
        ...mapGetters({
            tableData: TYPES.tableData,
            tableTotal: TYPES.tableTotal,
        }),
    },
    watch: {
        jcxids: {
            immediate: true,
            handler() {},
        },
    },
    mounted() {
        this.getEquipList();
        this.getSjjcxsjg();
        this.getEnumList();
    },
    methods: {
        ...mapActions([
            "getAllEnumInfobytableID",
            "getSelectData",
            "GetAllEquipmentList", //设备类型列表获取
            "DeleteSoilEquipment", //设备类型删除
            "GetBasicLeftTree", // 获取树结构1级目录下的实际监测项
            "SaveSoilEquipment", // 设备类型保存
            "DeleteCLJQR", //测量机器人删除服务
            "DeleteEquipmentIds", //删除服务
            "GetEnumItem",
        ]),

        async getEquipList() {
            let _this = this;
            this.equipData = [];
            let result = await this.GetAllEquipmentList();
            if (result && result.length > 0) {
                this.equipData = result;

                if (this.$route.query.activeName) {
                    this.activeName = this.$route.query.activeName;
                    if (this.activeName === "first") {
                        this.isSbqdMapShow = false;
                    }
                }

                let currentItem = null;
                if (this.$route.query.sbLx) {
                    this.equipData.forEach((item) => {
                        if (item.ID === _this.$route.query.sbLx) {
                            currentItem = item;
                        }
                    });
                }
                if (currentItem) {
                    this.clickHandler(currentItem);
                } else {
                    this.clickHandler(this.equipData[0]);
                }
            } else {
                this.getTableData();
            }
        },
        add() {
            this.addVis = true;
            this.typeValue = "";
            this.formData = {};
            this.jcxids = [];

            // 点击添加设备类型时，设备清单不显示，展示第1个标签（设备类型详情）。
            this.isSbqdShow = false;
            this.isSbqdMapShow = false;
            this.activeName = "first";
        },
        edit() {
            this.addVis = true;
            this.isSbqdMapShow = false;
            this.activeName = "first";
        },
        // 设备类型删除服务
        del() {
            let _this = this;
            _this
                .$confirm("是否永久删除该条记录?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            _this.delEquipType(done, instance);
                        } else {
                            done();
                        }
                    },
                })
                .then(() => {})
                .catch(() => {
                    _this.$message({
                        message: "已取消删除",
                        type: "info",
                    });
                });
        },
        // eslint-disable-next-line no-unused-vars
        async delEquipType(done, instance) {
            let res = await this.DeleteSoilEquipment({
                sblxid: this.formData.ID,
            });
            // this.delOver(res, done);
            if (res.IsSuccess) {
                done();
                this.$message({
                    message: "删除成功",
                    type: "success",
                });
                this.getEquipList();
            } else {
                this.$message({
                    message: "删除失败",
                    type: "error",
                });
            }
        },
        clickHandler(item) {
            this.isSbqdShow = true;
            this.addVis = false;
            this.typeValue = item.ID;
            this.formData = item;
            if (this.formData.SBSJCDX) {
                this.jcxids = JSON.parse(this.formData.SBSJCDX);
            } else {
                this.jcxids = [];
            }
            this.sbLx = this.formData.ID;
            this.sbMc = this.formData.MC;
            console.log(9999, this.sbLx, this.sbMc);
            this.getTableData();
        },

        // 获得监测数据左侧列表数据
        async getSjjcxsjg() {
            this.sjjcxsjgData = [];
            let result = await this.GetBasicLeftTree({
                lb: 1,
                departmentId: localStorage.userId,
            });

            result.map((item) => {
                item.Children.map((itemInner) => {
                    if (!itemInner.Children.length) {
                        itemInner.Children = null;
                    } else {
                        itemInner.Children.map((itemInnerInner) => {
                            if (!itemInnerInner.Children.length) {
                                itemInnerInner.Children = null;
                            }
                        });
                    }
                });
            });
            this.sjjcxsjgData = result;
        },
        // 点击保存按钮
        validate(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    //保存数据
                    this.saveHandler();
                } else {
                    return false;
                }
            });
        },
        async saveHandler() {
            if (this.jcxids && this.jcxids.length > 0) {
                this.formData.SBSJCDX = JSON.stringify(this.jcxids);
            } else {
                this.formData.SBSJCDX = null;
            }
            if (this.formData.MC) {
                let result = await this.SaveSoilEquipment({
                    data: this.formData,
                });
                if (result.IsSuccess) {
                    this.showSuccess();
                    this.getEquipList();
                } else {
                    this.showError();
                }
            } else {
                this.$message({
                    type: "warning",
                    message: `请填写设备名称`,
                });
            }
        },
        // 成功提示
        showSuccess() {
            this.$message({
                message: "保存成功",
                type: "success",
            });
        },
        // 保存不成功提示
        showError() {
            this.$message({
                message: "保存失败",
                type: "warning",
            });
        },

        async getTableData(val) {
            if (val === "mounted") return;
            // 处理筛选，支持多项同时筛选
            this.searchData = [
                {
                    type: "SBLX",
                    value: this.formData.ID,
                },
                {
                    type: "SBZT",
                    value: this.sbzt,
                },
            ];
            let searchList = [];
            if (this.searchData && this.searchData.length > 0) {
                this.searchData.forEach((item) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        let jsonObj = {
                            FieldName: item.type,
                            FieldValue: item.value,
                            OperatorChar: "=",
                        };
                        searchList.push(jsonObj);
                    }
                });
            }
            let jsonStr = JSON.stringify(searchList);
            this.tableGroupAttributes.loading = true;
            let res = await this.getSelectData({
                _cols: [], //字段集
                _keyword: this.keyword, //查询关键字
                _serarchCols: JSON.stringify([
                    "SBMC",
                    "LXMC",
                    "YCYSMC",
                    "SBBH",
                    "AZSJ",
                    "AZDWZMS",
                    "CJZQ",
                    "CJZQDW",
                    "SBZT",
                    "SCZQSJ",
                ]), //关键字字段集合
                _conditionList: jsonStr, //过滤条件集合
                _dicOrderby: { CJSJ: "desc" },
                _pageSize: this.tableGroupAttributes.snPage.tiaoshu, //每页最大记录数
                _pageIndex: this.tableGroupAttributes.snPage.currentPage, //页索引
                _returnSum: true, //是否返回记录总数
                _needVersion: false, //是否分版本查询
                _searchFormal: false, //T：查询最近的正式版本数据；F：查询最近的数据
                itemid: "116", //业务编号
            });

            res.data.map((item) => {
                item.CJSJ = item.CJSJ.split(" ")[0];
                item.AZSJ = item.AZSJ.split(" ")[0];
            });
            this.tableGroupAttributes.snTable.table.data = res.data || [];
            this.tableGroupAttributes.snPage.count = Number(res.sum) || 0;
            this.tableGroupAttributes.loading = false;

            if (this.sbMc.indexOf("测量机器人") !== -1) {
                let obj = {
                    label: "监测点名称",
                    prop: "JCDMC",
                };
                this.tableGroupAttributes.snTable.columns.columnsData.splice(
                    1,
                    0,
                    obj
                );
            }

            if (res.data && res.data.length > 0) {
                this.mapPoint = [];
                res.data.forEach((item) => {
                    if (item.AZDJD && item.AZDWD) {
                        this.mapPoint.push(item);
                    }
                });
                console.log(
                    "%c 🥑 this.mapPoint: ",
                    "font-size:20px;background-color: #F5CE50;color:#fff;",
                    this.mapPoint
                );
            } else {
                this.mapPoint = [];
            }
        },
        // 批量删除
        delSome() {
            let _this = this;
            if (this.multipleSelection.length === 0) {
                this.$message({
                    message: "请选择数据项！",
                    type: "warning",
                });
            } else {
                _this
                    .$confirm("是否永久删除选中记录?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        beforeClose: (action, instance, done) => {
                            if (action === "confirm") {
                                _this.delSomeCount = 0;
                                _this.delSomeData(
                                    _this.multipleSelection,
                                    done,
                                    instance
                                );
                            } else {
                                done();
                            }
                        },
                    })
                    .then(() => {})
                    .catch(() => {
                        _this.$message({
                            message: "已取消删除",
                            type: "info",
                        });
                    });
            }
        },
        async delSomeData(val, done, instance) {
            let _this = this;
            let res = null;
            if (this.sbMc.indexOf("测量机器人") !== -1) {
                res = await this.DeleteCLJQR({
                    id: val[_this.delSomeCount].ID,
                });
            } else {
                res = await this.DeleteEquipmentIds({
                    id: val[_this.delSomeCount].ID,
                });
            }
            if (res.IsSuccess) {
                _this.delSomeCount++;
                if (_this.delSomeCount === val.length) {
                    _this.delOver(res, done);
                } else {
                    _this.delSomeData(val, done, instance);
                }
            }
        },
        // 删除数据
        deleteData(id, value) {
            let _this = this;
            let checkBox = id;
            _this
                .$confirm("是否永久删除该条记录?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            _this.delData(checkBox, done, instance, value);
                        } else {
                            done();
                        }
                    },
                })
                .then(() => {})
                .catch(() => {
                    _this.$message({
                        message: "已取消删除",
                        type: "info",
                    });
                });
        },
        // eslint-disable-next-line no-unused-vars
        async delData(id, done, instance) {
            let res = null;
            if (this.sbMc.indexOf("测量机器人") !== -1) {
                res = await this.DeleteCLJQR({
                    id: id,
                });
            } else {
                res = await this.DeleteEquipmentIds({
                    id: id,
                });
            }
            this.delOver(res, done);
        },
        // eslint-disable-next-line no-unused-vars
        handleClick(tab, event) {
            if (this.activeName === "second") {
                // this.getTableData();
            }
        },

        toEditPage(value, type) {
            let id = "";
            let jcdId = "";
            let routerName = "";
            if (this.sbMc.indexOf("测量机器人") !== -1) {
                // 测量机器人
                routerName = this.measureRobotInfoPage;
                id = value && value.length > 0 ? value[0].SBID : "";
                jcdId = value && value.length > 0 ? value[0].ID : "";
            } else {
                routerName = this.infoPage;
                id =
                    value && value.length > 0
                        ? value[0].Id
                            ? value[0].Id
                            : value[0].ID
                        : "";
            }

            this.$router.push({
                name: routerName,
                query: {
                    type: type,
                    id: id, //addChild ? "" : ID,
                    ItemID: "",
                    basicType: "picture",
                    sbLx: this.sbLx,
                    activeName: this.activeName,
                    jcdId: jcdId,
                },
            });
        },

        // 地图展示
        showPointByMap() {
            this.isSbqdMapShow = true;
        },
        // 返回设备清单列表
        BackToSbqdTable() {
            this.isSbqdMapShow = false;
        },
        async getEnumList() {
            let res = await this.GetEnumItem({
                Enumid: "6dbe992a-5ebe-11ea-bd8d-000c2977b7fd",
            });
            let sbzt = this.tableGroupAttributes.snSearch.selectData.find(
                (ele) => ele.value === "sbzt"
            );
            sbzt.list = res;
        },
    },
};
</script>
<style lang="scss" scoped>
.equip-manage-base {
    width: 100%;
    height: calc(100% - 42px);
    display: flex;
    flex-flow: row nowrap;
}
.equip-manage-left {
    width: 350px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #cccccc;
}
.equip-manage-right {
    width: calc(100% - 370px);
    height: calc(100% - 40px);
    margin: 20px 0 0 20px;
    overflow-x: hidden;
    overflow-y: auto;
}

.equip-manage-type-form {
    width: 700px;
    height: 100%;
    padding-top: 30px;
    // margin: auto;
}
.iconbase {
    width: 28px;
    height: 28px;
    font-weight: bold;
    cursor: pointer;
}
.title {
    height: 40px;
    line-height: 2.5;
    border-right: 1px solid #e4eaea;
    position: relative;
}

.txt {
    padding-left: 25px;
    font-size: 15px;
    color: #1862ad;
}

.iconBorder {
    display: inline-block;
    position: absolute;
    top: 0px;
    right: 20px;
}

.tab {
    position: absolute;
    top: 36px;
    bottom: 0;
    left: 404px;
    right: 20px;
    overflow: auto;
}

.detail {
    padding-top: 30px;
    width: 700px;
    margin: auto;
}

.saveBtn,
.back-btn {
    width: 100px;
    height: 30px;
    color: #fff;
    background-color: rgb(40, 204, 217);
    border: 1px solid rgb(40, 204, 217);
    border-radius: 20px;
    box-shadow: rgb(40, 204, 217) 0px 1px 6px;
    cursor: pointer;
}
.back-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 401;
}

.buttondiv {
    width: 100px;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 20px;
}

.container {
    position: relative;
    height: 80px;
    border-top: 1px dashed #cccccc;
    // border-right: 1px solid #e4eaea;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}
.container:hover {
    background: #dfe3eb;
}

.img {
    padding-left: 25px;
    padding-right: 25px;
}

.equip-type.el-select {
    display: block;
}
.equip-tabs.el-tabs {
    height: calc(100% - 60px);
}

.equip-list-content {
    height: 100%;
}
.equip-map-content {
    position: relative;
    width: 100%;
    height: 100%;
}
.equipicon.el-icon-circle-check {
    font-size: 25px;
    color: #1862ad;
    position: absolute;
    right: 20px;
}
.lonlat-map {
    width: 100%;
    // height: 100%;
    height: 700px;
    // margin-top: 40px;
}
</style>
