<template>
    <div class="equip-map-content">
        <Mymap class="equip-map" :myMap.sync="myMap" :mapData="mapData"></Mymap>
        <div class="equip-map-legend">
            <div class="equip-legend-title">图例</div>
            <div
                class="equip-legend-item"
                v-for="(item, index) in equipData"
                :key="index"
            >
                <img
                    class="equip-legend-icon"
                    :src="
                        lxmcImgArr.indexOf(item.MC) !== -1
                            ? require(`@image/dataService_management/marker/${item.MC}_1.png`)
                            : require(`@image/dataService_management/marker/qt_1.png`)
                    "
                />
                <label class="equip-legend-label">{{ item.MC }}</label>
            </div>
            <div
                class="equip-legend-item"
                v-for="(item, index) in legendList2"
                :key="index"
            >
                <div
                    class="equip-legend-cicle"
                    :style="{ background: item.bgColor }"
                ></div>
                <label class="equip-legend-label">{{ item.SBZT }}</label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import "@libs/leaflet_plug/LeafletMarkercluster/leaflet.markercluster.js";
import "@libs/leaflet_plug/LeafletMarkercluster/MarkerCluster.css";
import "@libs/leaflet_plug/LeafletMarkercluster/MarkerCluster.Default.css";
export default {
    name: "equipmanage-map",
    data() {
        return {
            myMap: null,
            mapData: {
                zoom: 12.6,
                baseMapNum: 0,
                mapTypeBtn: false,
                resetBtnRight: "140px",
            },
            markerGroup: null,
            legendList2: [
                {
                    bgColor: "#38b0ba",
                    SBZT: "正常",
                },
                {
                    bgColor: "#d2413e",
                    SBZT: "故障",
                },
            ],
            lxmcImgArr: [
                "摄像机",
                "动物多样性监测器",
                "高精自动气象站",
                "生态监测球机",
                "水质五参数监测器",
                "土壤墒情监测站",
                "物联网自动虫情信息采集设备",
                "一体式气象站",
            ],
        };
    },
    props: {
        mapPoint: {
            type: Array,
            default: () => [],
        },
        equipData: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        mapPoint: {
            immediate: false,
            handler() {
                this.drawMarker(this.markerGroup, this.mapPoint);
            },
        },
    },
    mounted() {
        this.markerPopup = L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, -10],
            className: "markerPopup",
        });
        this.markerGroup = window.L.layerGroup().addTo(this.myMap);
        this.drawMarker(this.markerGroup, this.mapPoint);
        this.initCluster();
    },
    methods: {
        ...mapActions([]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        initCluster() {
            // 初始化聚合图层
            let _this = this;
            let map = this.myMap;
            let selectID = this.selectID;
            let markers = window.L.markerClusterGroup({
                maxClusterRadius: 20,
                spiderfyOnMaxZoom: false,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: false,
                iconCreateFunction: function (cluster) {
                    let da = cluster.getAllChildMarkers();
                    let warn = 1;
                    da.forEach(function (element) {
                        if (element.data.YJSFJC === 0) {
                            warn = 0;
                        }
                    });
                    let css = warn
                        ? "markerSelected"
                        : "css_animation markerSelected";
                    let html1 = `<div class='${css}' style='background-image:url("static/img/legend/JCZT/warn${warn}.png")'></div>`;
                    let html2 =
                        "<div class='icon-number'>" +
                        cluster.getChildCount() +
                        "</div>";
                    let options = {
                        html: "<div>" + html1 + html2 + "</div>",
                        iconAnchor: [8, 19] || [15, 34],
                        selfType: "cluster",
                    };

                    for (let i = 0; i < da.length; i++) {
                        if (selectID === da[i].data.ID) {
                            options.className = "selectedAnimation";
                            break;
                        }
                    }
                    return window.L.divIcon(options);
                },
            });
            _this.markers = markers;
            map.addLayer(markers);
        },
        async drawMarker(group, list) {
            this.clearLayersGroup(group);
            list.forEach((item, index) => {
                // 新建的设备没有设备状态 不显示
                if (item.SBZT == null) {
                    return;
                }
                let myIcon = window.L.icon({
                    // iconUrl: require(`@image/dataService_management/marker/${item.LXMC}_${item.SBZT}.png`),
                    iconUrl: require(`@image/dataService_management/marker/${
                        this.lxmcImgArr.indexOf(item.LXMC) !== -1
                            ? item.LXMC
                            : "qt"
                    }_${item.SBZT}.png`),
                    iconSize: [25, 25],
                    iconAnchor: [12, 12],
                });
                let layer = window.L.marker([item.AZDWD, item.AZDJD], {
                    icon: myIcon,
                    attribution: item,
                }).addTo(group);
                layer.on("mouseover", () => {
                    this.markerPopup
                        .setLatLng([item.AZDWD, item.AZDJD])
                        .setContent(`<div class="tip">${item.SBMC}</div>`)
                        .openOn(this.myMap);
                });
                layer.on("mouseout", () => {
                    this.markerPopup.removeFrom(this.myMap);
                });
                if (item.YCJLLIST && item.YCJLLIST.length) {
                    let divIcon = window.L.divIcon({
                        className: "my-div-icon",
                        html:
                            `<div style="width:16px;height:16px;background:red;color:#fff;border-radius:50%;line-height: 16px;text-align:center;">` +
                            item.YCJLLIST.length +
                            "</div>",
                        iconAnchor: [-3, 38],
                    });
                    window.L.marker([item.AZDWD, item.AZDJD], {
                        icon: divIcon,
                    }).addTo(group);
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.equip-map-content {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 10px;
}
.equip-map-legend {
    position: absolute;
    left: 18px;
    bottom: 18px;
    padding: 16px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: -2px 2px 5px #ccc;
    z-index: 400;
}
.equip-legend-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    text-align: center;
    line-height: 30px;
}
.equip-legend-item {
    display: flex;
    flex-flow: row nowrap;
    margin: 5px 0 0 0;
    .equip-legend-icon {
        width: 20px;
        height: 20px;
    }
    .equip-legend-cicle {
        width: 20px;
        height: 20px;
        border-radius: 10px;
    }
    .equip-legend-label {
        margin: 0 0 0 10px;
        letter-spacing: 2px;
        font-size: 13px;
    }
}
</style>
